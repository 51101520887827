import { graphql, StaticQuery } from "gatsby";
import React from "react";

// Components
import ContentHeader from "../components/ContentHeader";

// Assets
// import coinAnimation from '../animations/sponsors.json';
// import BookletCard from '../components/BookletCard';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import BalancingCard from "../components/BalancingCard";

export const query = graphql`
  query SponsorPageQuery {
    allCompanies(filter: { sponsor: { eq: true } }) {
      nodes {
        identifier
        name
        website
        logo {
          childImageSharp {
            gatsbyImageData(
              height: 300
              width: 300
              placeholder: TRACED_SVG
              formats: [AUTO, WEBP, AVIF]
              transformOptions: { fit: INSIDE }
            )
          }
        }
      }
    }
    brochure: file(name: { eq: "sponsor_brochure_2024" }) {
      publicURL
    }
    banner: file(name: { eq: "justitia-banner" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          height: 600
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          transformOptions: { fit: COVER, cropFocus: CENTER }
        )
      }
    }
    currentYear {
      year
      color
    }
  }
`;

export default class SponsorsPage extends React.Component {
  render() {
    return (
      <StaticQuery
        query={query}
        render={(data) => (
          <div className="flex flex-col">
            <ContentHeader
              title="Sponsoren"
              backLink="/"
              backLabel="Overzicht"
              colored_banner={true}
              background={data.banner.childImageSharp.gatsbyImageData}
            />
            <div className="flex flex-col items-center gap-20 p-8 sm:pb-28 sm:pt-24">
              <div className="grid max-w-screen-lg border-separate grid-flow-row-dense content-center items-center justify-center gap-16 sm:grid-cols-2 md:grid-cols-3">
                {data.allCompanies.nodes.map((node: any) => {
                  const logo = getImage(node.logo);
                  return (
                    <a
                      href={node.website}
                      target="_blank"
                      rel="noreferrer"
                      key={node.identifier}
                    >
                      <BalancingCard amount={0.2}>
                        <div
                          className={
                            (node.sponsor_level ?? "").length > 0
                              ? "bg-primary relative h-full overflow-hidden rounded-xl bg-opacity-5 p-8"
                              : ""
                          }
                        >
                          {node.sponsor_level ===
                          "Hoofdsponsor Pleitwedstrijden" ? (
                            <div
                              className="absolute left-0 top-0 h-full w-full"
                              style={{
                                backgroundColor: data.currentYear.color,
                                opacity: 0.5,
                              }}
                            />
                          ) : null}
                          <p className="pb-6 text-center text-lg font-medium">
                            {node.sponsor_level}
                          </p>
                          <div className="flex select-none justify-center transition-opacity hover:opacity-50 active:opacity-20">
                            {logo ? (
                              <GatsbyImage
                                image={logo}
                                alt={"Logo voor " + node.name}
                                draggable={false}
                                objectFit="contain"
                                imgClassName="h-16 w-auto"
                              />
                            ) : null}
                          </div>
                        </div>
                      </BalancingCard>
                    </a>
                  );
                })}
              </div>
              <div className="flex flex-col gap-8">
                <p className="text-2xl max-w-lg font-black text-primary text-opacity-50 leading-snug text-center">
                  Justitia 2024 werd mede mogelijk gemaakt door bovenstaande
                  sponsoren
                </p>
              </div>
              {/* <div className="text-primary flex max-w-xl flex-col gap-4 text-lg font-semibold">
                <h2 className="text-4xl font-bold">Word ook sponsor!</h2>
                <p>
                  Sponsoring van Justitia is de uitgelezen kans om uw
                  organisatie in contact te brengen met beginnende advocaten uit
                  verschillende hoeken van de Amsterdamse advocatuur. Heeft u
                  interesse, download dan onze sponsorbrochure of neem contact
                  op per mail.
                </p>
                <a
                  href="mailto:info@stichtingjustitia.nl"
                  className="text-md bg-primary display-inline rounded-lg px-4 py-2 text-white transition-opacity hover:opacity-80 active:opacity-60"
                >
                  info@stichtingjustitia.nl
                </a>
                <BookletCard
                  text="Klik hier om de sponsorbrochure van 2024 te bekijken"
                  url={data.brochure.publicURL}
                />
              </div> */}
            </div>
          </div>
        )}
      />
    );
  }
}

export function Head() {
  return <title>Sponsoren - Stichting Justitia</title>;
}
